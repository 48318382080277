@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins";
}
.header-btndiv {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}
.header-btndiv h1 {
  color: #202620;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin: 0;
}
.header-btndiv button {
  background-color: #ffffff;
  border: 1px solid #be763733;
  color: #be7637;
  width: 188.5px;
  height: 50px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
}
.css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper {
  width: 291px !important;
}
.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
  width: calc(100% - 291px) !important;
  margin-left: 291px !important;
  background-color: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #be763733 !important;
  display: unset !important;
}
.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}
.loc-img {
  background-color: #be7637;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
}
.cou-img {
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 5px;
}
.MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  width: 30px !important;
  height: 30px !important;
  background: white;
  max-width: 30px !important;
  border: 1px solid #be76371a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  padding: 5px;
  min-width: 30px !important;
  box-shadow: 0px 4px 4px #00000012;
  border-radius: 5px;
}

.css-10hburv-MuiTypography-root {
  color: #202620;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.css-1fzmbpm-MuiListItemText-root {
  padding-left: unset !important;
}
#submenu {
  padding-left: 25px;
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}
.addicon {
  background-color: #be7637;
  border-radius: 50%;
  color: #fff;
}
.arrow {
  color: #be7637;
}

.css-9mgopn-MuiDivider-root {
  border-color: #be763733 !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: 1px solid #be763733 !important;
}
img.logoimg {
  width: 150px;
  height: 61.5px;
  margin: 15px 65px;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  border: 1px solid #be763733 !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.side_top_menu ul.dropdown-menu.show {
  margin-left: 120px !important;
  min-width: 200px;
}

.btn-secondary.dropdown-toggle.top-main.show {
  box-shadow: none !important;
}
.top-main {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 20px;
  left: auto;
  position: absolute;
  right: 25px;
  top: 10px;
  /* display: flex;
  align-items: center;
  gap: 20px;
  right: 30px;
  left: auto;
  position: absolute;
  cursor: pointer; */
}
.Profileimg {
  border-radius: 50%;
  height: 40px;
  box-shadow: 1px 1px 7px #dad1d1;
  width: 40px;
}
.top-midtext {
  line-height: 10px;
  padding-top: 15px;
}
.top-midtext p:first-child {
  color: #be7637;
}
.top-midtext p:last-child {
  color: #202620;
}
.css-hyum1k-MuiToolbar-root {
  min-height: 92px !important;
  background-color: #fff;
}
.logo-buttom {
  border-bottom: 1px solid #be763733 !important;
}

.top-icon
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #202620;
}
.css-1v5u2yv {
  margin-left: 55px;
}
.step-div {
  width: 50%;
  display: block;
  margin: 35px auto;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #be7637 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: transparent !important;
  border: 1px solid #be763733 !important;
  border-radius: 50px;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active
  .css-117w1su-MuiStepIcon-text {
  fill: #fff;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root .css-117w1su-MuiStepIcon-text {
  fill: #5a5a5a;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #be7637 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 3.5rem !important;
}
span.MuiStepLabel-root.MuiStepLabel-horizontal.css-ascpo7-MuiStepLabel-root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.css-j5w0w9-MuiStepConnector-root {
  margin-bottom: 20px !important;
}

.activeSubmenuItem
  .color-text
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
  color: #be7637;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
}
.activeMenu
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
  color: #be7637;
}
.css-j5w0w9-MuiStepConnector-root {
  margin-bottom: 40px !important;
}
.top-main .dropdown-toggle::after {
  content: none !important;
}

.btn-secondary.dropdown-toggle.top-main.show:hover {
  background: transparent;
}
.dropdown-toggle::after {
  display: none;
}
ul.dropdown-menu.show li {
  /* padding: 6px 20px; */
  border-bottom: 1px solid #be763726;
  min-height: 40px;
  display: flex;
  align-items: center;
}
a.dropdown-item {
  font-size: 13px;
  position: relative;
  font-weight: 500;
  color: #202620;
  white-space: pre-wrap !important;
  max-width: 550px;
}

.dropdown-item:hover {
  background-color: transparent !important;
  color: #202620;
}
a.dropdown-item::before {
  width: 0px;
  height: 0px;
  content: "";
  background: #be7637;
  border-radius: 30px;
  position: absolute;
  left: 0px;
  top: 15px;
  text-decoration: none;
}
.activeMenu .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root img {
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(7500%)
    hue-rotate(267deg) brightness(107%) contrast(102%);
}

.activeMenu .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  background: #bd753e;
  border-radius: 4px;
}
#firstmenu .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root img {
  filter: unset;
}
.MuiList-root.css-1mk9mw3-MuiList-root
  .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.inactiveSubmenuItem.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root
  .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  border: none;
  padding: 0 !important;
  box-shadow: none;
}

.activeSubmenuItem .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  border: none !important;
  box-shadow: none;
}
.Mui-completed span {
  border-color: #d18845 !important;
}
.Mui-active span {
  border-color: #d18845 !important;
}
.css-z7uhs0-MuiStepConnector-line {
  border-top-width: 2px !important;
}
.header-btnicon {
  height: 35px;
  width: 35px;
  border: 1px solid #be7637;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.header-btnicon svg {
  fill: #be7637;
}
.header-icondiv h1 {
  color: #202620;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0px;
}
.header-icondiv {
  display: flex;
  align-items: center;
  gap: 10px;
  /* justify-content: center; */
}
.btn-check:checked + .btn-secondary:hover,
.btn-check:active + .btn-secondary:hover,
.btn-secondary:active:hover,
.btn-secondary.active:hover,
.btn-secondary.show:hover {
  background-color: transparent !important;
}

/* Live css*/
header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed.css-1kvaj0b {
  width: calc(100% - 291px) !important;
  margin-left: 291px !important;
  background-color: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #be763733 !important;
}
.css-i6s8oy {
  min-height: 92px !important;
  background-color: #fff !important;
}
.css-1j95noh .MuiDrawer-paper {
  width: 291px !important;
}
.top-icon svg {
  color: #202620;
}
.css-1ni0dl2 {
  padding-left: unset !important;
}
.css-1f8bwsm {
  min-width: 35px !important;
}
.activeMenu
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #be7637;
}
activeSubmenuItem
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #be7637;
}
.activeMenu img {
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(7500%)
    hue-rotate(267deg) brightness(107%) contrast(102%);
}
.css-1abj2s5 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column !important;
  gap: 20px !important;
}
.css-4ff9q7.Mui-active {
  color: #be7637 !important;
}
.css-4ff9q7 {
  font-size: 3.5rem !important;
}
span.MuiStepLabel-label.Mui-completed.css-2fdkz6 {
  color: #be7637 !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}
span.MuiStepLabel-label.Mui-active.css-2fdkz6 {
  color: #5a5a5a !important;
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 600 !important;
}
.css-4ff9q7.Mui-completed {
  color: #be7637 !important;
}
.Mui-disabled .css-4ff9q7 {
  color: transparent !important;
  border: 1px solid #be763733 !important;
  border-radius: 50%;
}
.Mui-disabled text.MuiStepIcon-text.css-10psl7k {
  fill: #5a5a5a;
}
.MuiListItemIcon-root.css-1f8bwsm {
  background-color: #be7637;
  width: 30px !important;
  height: 30px !important;
  background: white;
  max-width: 30px !important;
  border: 1px solid #be76371a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  padding: 5px;
  min-width: 30px !important;
  box-shadow: 0px 4px 4px #00000012;
  border-radius: 5px;
}
.activeMenu .MuiListItemIcon-root.css-1f8bwsm {
  background: #bd753e;
  border-radius: 4px;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #202620;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #202620;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19.5px;
}
.activeSubmenuItem
  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  color: #be7637 !important;
}
#firstmenu .MuiListItemIcon-root.css-1f8bwsm img {
  filter: unset !important;
}

/* For iPads */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .login_section .row {
    flex-direction: column-reverse;
  }
  .login_section {
    height: 100vh !important;
  }
  .login_form_content {
    align-items: unset !important;
    height: 38vh !important;
    margin-top: 40px;
  }
  .image_section img {
    height: 60vh;
  }
  .search-div {
    justify-content: unset !important;
  }
  .reservation-actionbtn {
    display: flex;
    align-items: center;
  }
  .select-div {
    flex-direction: column;
  }
  .pro-imgdiv {
    flex-direction: column;
  }
  .second-part input {
    width: 300px !important;
}
.second-part .form-select {
    width: 300px !important;
}
.second-part textarea#floatingTextarea2 {
    width: 300px !important;
}
  .MuiBox-root.css-k008qs {
    width: 100%;
    overflow: hidden;
  }
  .css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    width: calc(100% - 225px) !important;
    margin-left: 225px !important;
  }

  .css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper {
    width: 225px !important;
    overflow: hidden;
  }
  .css-1v5u2yv {
    margin-left: unset;
  }
  .add-table {
    overflow: auto !important;
  }
  .hunter-serch {
    width: unset;
  }
  .reservation_content p {
    width: 100px;
    word-wrap: break-word;
  }
  .pro-imgdiv {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .login_section .row {
    flex-direction: column-reverse;
  }
  .login_section {
    height: 100vh !important;
  }
  .login_form_content {
    align-items: unset !important;
    height: 57vh !important;
    /* margin-top: 40px; */
  }
  .image_section img {
    height: 40vh;
  }
  .search-div {
    justify-content: unset !important;
  }
  .reservation-actionbtn {
    display: flex;
    align-items: center;
  }
  .content_section {
    padding: 20px;
  }
  .select-div {
    flex-direction: column;
  }
  .pro-imgdiv {
    flex-direction: column;
  }
  .areas\&slot-buttion {
    gap: 10px !important;
  }
  .areas\&slot-buttion .button2 {
    padding: 16px 50px !important;
  }
  .pro-imgdiv1 img {
    width: 300px !important;
    height: 260px !important;
  }
  .managecou-content {
    width: 365px !important;
  }
  .managecou-contentdiv p {
    margin: 10px 30px 45px 30px !important;
  }
  .managecou-btn .btn1 {
    padding: 15px 50px !important;
  }
  .managecou-btn .btn2 {
    padding: 15px 50px !important;
  }
  .chagepassword-content {
    margin: 15px !important;
  }
  .logout-content {
    width: 365px !important;
  }
  .logout-btn .btn1 {
    padding: 15px 50px !important;
  }
  .logout-btn .btn2 {
    padding: 15px 50px !important;
  }
  .logout-contentdiv p {
    margin: 10px 30px 45px 30px !important;
  }
  .profile-contentmain {
    align-items: center !important;
  }
  .profile-content-icon p {
    word-break: break-word !important;
    margin-bottom: 0 !important;
  }
  .profile-content {
    gap: unset !important;
    flex-direction: column !important;
  }
  .profile-details-content {
    align-items: unset !important;
    flex-direction: column;
  }
  .second-part input {
    width: 280px !important;
  }
  .second-part .form-select {
    width: 280px !important;
  }
  .second-part textarea#floatingTextarea2 {
    width: 280px !important;
  }
  .MuiBox-root.css-k008qs {
    width: 100%;
    overflow: hidden;
  }
  .profile-details-content {
    padding: 5px 5px 25px 5px !important;
}
.first-part p {
    margin-bottom: 10px !important;
}
.second-part p {
    margin-bottom: 0px !important;
}
.areas\&slot-buttion .button1 {
  padding: 16px 30px !important;
}
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.css-6iz4mj-MuiButtonBase-root-MuiIconButton-root {
    background-color: #202620;
    margin-right: 8px;
  }
  .css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    width: 100% !important;
  }
  .css-1v5u2yv {
    margin-left: unset;
    width: 15%;
  }
  .top-main {
    gap: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .dashboard-section {
    flex-direction: column;
  }
  .dashboard-section {
    gap: 30px;
  }
  .dashboard-subsection {
    width: 100% !important;
    cursor: pointer;
  }
  .search-div {
    justify-content: unset !important;
    flex-direction: column;
  }
  .add-table {
    overflow: auto !important;
  }
  .hunter-serch {
    width: unset !important;
  }
  button.add-btn {
    max-width: unset !important;
  }
  .reservation_divider {
    height: 215px !important;
}
.reservation_content p {
    width: 115px !important;
    word-wrap: break-word !important;
}
.reservation_part:nth-child(2) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #be763733 !important;
}
.reservation_part:nth-child(3) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 20px;
}


.reservation_part:last-child {
  margin-top: 20px;
}
ul.dropdown-menu.show li:first-child {
  height: 100px !important;
}
.user-drop {
  display: flex;
  gap: 10px;
  align-items: center;
}
ul.dropdown-menu.show {
  transform: translate3d(-10px, 74px, 0px) !important;
}
  /* .reservation_divider {
    display: flex;
    flex-wrap: wrap;
  }
  .reservation_part {
    flex: 1 0 50%;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
  } */
}
@media only screen and (max-width: 480px) {
  .login_section .row {
    flex-direction: column-reverse;
  }
  .login_section {
    height: 100vh !important;
  }
  .login_form_content {
    align-items: unset !important;
    height: 65vh !important;
    /* margin-top: 40px; */
  }
  .image_section img {
    height: 38vh;
  }
  .content_section {
    padding: 20px;
  }
  .reservation-actionbtn {
    display: flex;
    align-items: center;
  }
  .search-div {
    justify-content: unset !important;
  }
  .select-div {
    flex-direction: column;
  }
  .pro-imgdiv {
    flex-direction: column;
  }
  .areas\&slot-buttion {
    gap: 10px !important;
  }
  .areas\&slot-buttion .button2 {
    padding: 16px 50px !important;
  }
  .pro-imgdiv1 img {
    width: 300px !important;
    height: 260px !important;
  }
  .managecou-content {
    width: 365px !important;
  }
  .managecou-contentdiv p {
    margin: 10px 30px 45px 30px !important;
  }
  .managecou-btn .btn1 {
    padding: 15px 50px !important;
  }
  .managecou-btn .btn2 {
    padding: 15px 50px !important;
  }
  .chagepassword-content {
    margin: 15px !important;
  }
  .logout-content {
    width: 365px !important;
  }
  .logout-btn .btn1 {
    padding: 15px 50px !important;
  }
  .logout-btn .btn2 {
    padding: 15px 50px !important;
  }
  .logout-contentdiv p {
    margin: 10px 30px 45px 30px !important;
  }
  .profile-contentmain {
    align-items: center !important;
  }
  .profile-content-icon p {
    word-break: break-word !important;
    margin-bottom: 0 !important;
  }
  .profile-content {
    gap: unset !important;
    flex-direction: column !important;
  }
  .profile-details-content {
    align-items: unset !important;
    flex-direction: column;
  }
  .second-part input {
    width: 280px !important;
  }
  .second-part .form-select {
    width: 280px !important;
  }
  .second-part textarea#floatingTextarea2 {
    width: 280px !important;
  }
  .profile-details-content {
    padding: 5px 5px 25px 5px !important;
}
.first-part p {
    margin-bottom: 10px !important;
}
.second-part p {
    margin-bottom: 0px !important;
}
  .MuiBox-root.css-k008qs {
    width: 100%;
    overflow: hidden;
  }
  .areas\&slot-buttion .button1 {
    padding: 16px 30px !important;
  }
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.css-6iz4mj-MuiButtonBase-root-MuiIconButton-root {
    background-color: #202620;
    margin-right: 8px;
  }
  .css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    width: 100% !important;
  }
  .css-1v5u2yv {
    margin-left: unset;
    width: 15%;
  }
  .top-main {
    gap: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .dashboard-section {
    flex-direction: column;
  }
  .dashboard-section {
    gap: 30px;
  }
  .dashboard-subsection {
    width: 100% !important;
    cursor: pointer;
  }
  .search-div {
    justify-content: unset !important;
    flex-direction: column;
  }
  .add-table {
    overflow: auto !important;
  }
  .hunter-serch {
    width: unset !important;
  }
  button.add-btn {
    max-width: unset !important;
  }
  .reservation_divider {
    height: 215px !important;
}
.reservation_content p {
    width: 115px !important;
    word-wrap: break-word !important;
}
.reservation_part:nth-child(2) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #be763733 !important;
}
.reservation_part:nth-child(3) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 20px;
}
.reservation_part:last-child {
  margin-top: 20px;
}
ul.dropdown-menu.show li:first-child {
  height: 100px !important;
}
.user-drop {
  display: flex;
  gap: 10px;
  align-items: center;
}
ul.dropdown-menu.show {
  transform: translate3d(-10px, 74px, 0px) !important;
}
  /* .reservation_divider {
    display: flex;
    flex-wrap: wrap;
  }
  .reservation_part {
    flex: 1 0 50%;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
  } */
}

.btn-secondary.dropdown-toggle.top-main.belldiv img {
  width: 26px;
  position: absolute;
  right: 300px;
  top: -16px;
}
l.dropdown-menu.show {
  transform: translate(1430px, 82px) !important;
}


.btn-secondary.dropdown-toggle.belldiv {
  position: relative;
}

p.bell_notification {
  align-items: center;
  background: red;
  border-radius: 40px;
  color: #fff;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 3px;
  position: absolute;
  text-align: center;
  top: -12px;
  right: -10px;
  min-width: 24px;
}
ul.dropdown-menu.bell_drop.show {
  background: #fff;
  max-height: 450px;
  overflow: auto !important;
  /* right: 320px !important; */
  width: 550px;
  top: 16px !important;
} 

h4.notification-title {
  padding: 12px;
  color: #202620;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-align: start;
  border-bottom: 1px solid #be763733 !important;
  margin-bottom: 0px;
}

.no-notification {
  align-items: center;
  color: #000;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-icondiv1 h1 {
  color: #202620;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 0px;
}