/* :root {
  --main-background-color: red;
  --main-button: #57483b;
} */
/* dashboard page  */

.dashboard-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.outline-container {
  display: flex;
  gap: 20px;
}
.left-side {
  width: 70%;
}
.left-sidemain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-side {
  width: 30%;
  overflow: auto;
  height: 100vh;
  position: relative;
}
.dashboard-subsection {
  display: flex;
  justify-content: space-between;
  border: 1px solid #be763733;
  width: 49%;
  cursor: pointer;
  padding: 25px;
  border-radius: 10px;
}
.dashboard-subsections {
  display: flex;
  justify-content: space-between;
  border: 1px solid #be763733;
  width: 100%;
  cursor: pointer;
  padding: 15px 25px 0;
  border-radius: 10px;
}
.edit-icon{
    background: white;
    width: 28px;
    height: 28px;
    padding: 5.5px;
    border-radius: 5px;
    cursor: pointer;
}
.delete-icon{
  background: white;
    width: 28px;
    height: 28px;
    padding: 5.5px;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-contant p {
  margin-bottom: 0px !important;
}

.dashboard-contant p {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #5a5a5a;
  margin-bottom: 10px;
}

.dashboard-contant h1 {
  font-size: 48px;
  line-height: 72px;
  font-weight: 600;
  margin-bottom: 0px;
}

.dashboard-img img {
  height: 96px;
  width: 96px;
}

.add-formdiv {
  border: 1px solid #be763733;
  border-radius: 10px;
}

.add-formdiv-media {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #be763733;
  border-radius: 10px;
}

.add-top h2 {
  color: white;
  font-weight: 500;
  font-size: 17px;
  background: #be7637;
  padding: 15px;
  border-radius: 10px 10px 0px 0px;
  text-align: start;
}

.add-form {
  padding: 15px 20px 30px 20px;
}

.form-label {
  color: unset;
  font-size: 14px;
  font-weight: 500;
}

input.form-control {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #be763733;
}

.reject-reason {
  max-height: 80px;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  border: 1px solid #be763733;
}

.select-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
}

.required-star{
  color: red;
}

.select-div4 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
}

.reservation-idtable .add-form.pagination-table td:nth-child(7) {
  width: 100px;
}


.reservation-idtable .add-form.pagination-table td:nth-child(8) {
  min-width: 185px;
}

.reservation-idtable .add-form.pagination-table td:nth-child(3) {
  width: 200px;
}


.reservation-idtable .add-form.pagination-table td:nth-child(9) {
  text-align: end;
  width: 70px;
}

.reservation-idtable .add-form.pagination-table th:nth-child(9) {
  text-align: end;
  width: 70px;
}



.reservation-idtable .add-form.pagination-table td:nth-child(6) {
  text-align: end;
  width: 50px;
}

.reservation-idtable .add-form.pagination-table th:nth-child(6) {
  text-align: end;
  min-width: 165px;
}


.select-div > .select-div1+.select-div1 {
  display: flex;
}


.select-div1,
.select-div2 {
  flex: 1;
  margin-right: 10px;
}

.select-div1:last-child,
.select-div2:last-child {
  margin-right: 0;
}

.form-select {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #be763733;
  margin-bottom: 0px !important;
}

textarea#floatingTextarea2 {
  border-radius: 10px;
  border: 1px solid #be763733;
  height: 90px;
}

.add-btn {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}


.header-btndiv1 .coupon-search {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

.download-btn {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}


.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #666666;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: auto; 
  margin-top: 0px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.add-btn-hunter {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 65px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

.add-form table {
  margin: 0;
}

.add-form th {
  background-color: #fbf6f1;
}

button.add-btn {
  width: 100%;
  max-width: 215px;
  padding: 10px;
  height: 50px;
}

button.add-btn-huter {
  width: 100%;
  max-width: 215px;
  padding: 10px;
  height: 50px;
}

.mapboxgl-ctrl-attrib-inner{
  display: none;
}
.mapboxgl-ctrl-attrib{
  display: none;
}
.td-p {
  font-size: 14px;
  font-weight: 400;
  width: 70%;
  text-align: right;
}
.outline-bottom {
  margin: 20px 0;
}
.outline-top {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #be763733;
}
.pro-details {
  display: flex;
  justify-content: space-between;
  padding: 6px 15px 0px 15px;
}

a.pro-link {
  text-decoration: none;
}

.managecou-btn {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.managecou-btn .btn1 {
  width: 190px;
  padding: 9px 78px;
  border-radius: 10px;
  border: 1px solid #f15e5e;
  background: #fff;
  color: #f15e5e;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.managecou-btn .btn2 {
  width: 190px;
  padding: 11px 78px;
  border-radius: 10px;
  border: 1px solid #f15e5e;
  background: #f15e5e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
}

.managecou-btn .btnApprove2 {
  width: 190px;
  padding: 15px 78px;
  border-radius: 10px;
  border: 1px solid #be7637;
  background: #be7637;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.add-form.pagination-table .add-table {
  border-bottom: none !important;
  border-radius: 10px 10px 0px 0px;
}

.managecou-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.managecou-reject {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 450px;
  height: 470px;
}

.managecou-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 450px;
  /* height: 359px; */
}

.managecou-contentdiv {
  text-align: center;
  margin: 20px 0;
}


.reservation-content .fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #5a5a5a;
  width: 20px;
  height: 20px;
}

.reservation-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 450px;
  height: 450px;
}

.reservation-contentdiv {
  text-align: center;
  margin: 20px 0;
}


.reservation-contentdiv img {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.reservation-contentdiv h1 {
  color: #202620;
  font-size: 22px;
  font-weight: 600;
}

.reservation-contentdiv p {
  color: #5a5a5a;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 10px 60px 45px 60px;
}

.managecou-reject .fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #5a5a5a;
  width: 20px;
  height: 20px;
}

.managecou-content .fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #5a5a5a;
  width: 20px;
  height: 20px;
}

.managecou-contentdiv img {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.managecou-contentdiv h1 {
  color: #202620;
  font-size: 22px;
  font-weight: 600;
}

.managecou-contentdiv p {
  color: #5a5a5a;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 10px 60px 45px 60px;
}

.add-form th {
  font-size: 14px !important;
  color: #202620 !important;
  font-weight: 500 !important;
}

.add-form td {
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
}

.slot-datetable {
  display: flex;
  justify-content: space-between;
  background-color: #be7637;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
}

.slot-datetable1 h2 {
  background-color: unset;
  border-radius: unset;
}

.slot-datetable2 {
  display: flex;
  gap: 10px;
  margin-right: 15px;
}

.slot-datetable2 img {
  background: white;
  width: 28px;
  height: 28px;
  padding: 4.5px;
  border-radius: 5px;
  cursor: pointer;
}

.slot-datetable2 i {
  background: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  color: #be7637;
}

.slot-datetable2 img {
  background: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  color: #be7637;
}

.add-formdiv-pro {
  border: 1px solid #be763733;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.areas\&slot-div {
  margin-top: 20px;
}

.areas\&slot-buttion {
  display: flex;
  gap: 15px;
}

.areas\&slot-buttion .button1 {
  background-color: #be7637;
  border: 1px solid #be7637;
  color: #fff;
  padding: 16px 50px;
  font-size: 15px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.areas\&slot-buttion .button2 {
  background-color: transparent;
  border: 1px solid #be763733;
  color: #202620;
  padding: 16px 50px;
  font-size: 15px;
  font-weight: 500;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.table> :not(caption)>*>* {
  padding: 0.7rem 1.4rem;
}

.areas\&slot-div .add-table {
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.add-form a {
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
  text-decoration: underline;
}

.search-div {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin: 10px 10px 10px 10px;
}

.search-div .add-btn {
  padding: 12px 65px;
}

.search-div .download-btn {
  padding: 11px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 235px;
}

.search-div .add-btn-hunter {
  padding: 10px 50px;
  height: 50px;
}

.hunter-serch {
  width: 430px;
  font-size: 14px;
  font-weight: 400;
}

.reservation-actionbtn i {
  margin-right: 6px;
}

.reservation-actionbtns{
  min-width: 115px;
}
.reservation-actionbtns i {
  margin-right: 6px;
}
.Action_Reservation_Cancel .reservation-actionbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  color: #be7637;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.Action_Reservation_Cancelled .reservation-actionbtn {
  background-color: #ffcccc;
  border: 1px solid #ffcccc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  cursor: default;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.Action_Reservation_Pending .reservation-actionbtn {
  background-color: #e4e5e4;
  border: 1px solid #e4e5e4;
  color: #5a5a5a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
}
.Action_Reservation_Pending .reservation-actionbtns {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
}
.Action_Reservation_Booked .reservation-actionbtns {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
}

.add-table.Reservation_details_table .Action_Reservation_Cancel {
  width: 350px;
}


.Action_Reservation_Cancel .reservation-actionbtns {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
}

.Action_Reservation_Cancelled .reservation-actionbtns {
  background-color: #fcc;
  border: 1px solid #fcc;
  color: #f8285a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
  line-height: 18px;
  text-transform: capitalize;
}
.Action_Reservation_Booked .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  cursor: default;
  font-weight: 500;
  line-height: 18px;
}
.Action_cancelled .reservation-actionbtn {
  background-color: #e4e5e4;
  border: 1px solid #e4e5e4;
  color: #5a5a5a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.Action_completed .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}





.Action_Reservation_Pending .reservation-actionbtn {
  background-color: #e4e5e4;
  border: 1px solid #e4e5e4;
  color: #5a5a5a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.Action_Reservation_Booked .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.Action_Reservation_Cancelled .reservation-actionbtn {
  background-color: #fcc;
  border: 1px solid #fcc;
  border-radius: 16px;
  color: #f8285a;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 6px 18px;
}




.hanter-main td:nth-child(4) {
  text-align: center;
  padding-right: 12%;
}

a.hunter-link {
  text-decoration: underline;
  color: #be7637;
}

.hunter-ditailsdiv td:nth-child(6) {
  color: #be7637 !important;
}

.hunter-ditailsdiv td:first-child {
  color: #be7637 !important;
}

.Action_Pending .reservation-actionbtn {
  background-color: #e4e5e4;
  border: 1px solid #e4e5e4;
  color: #5a5a5a;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.Action_Connected .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.landowner-main td:nth-child(5) {
  text-align: center;
  padding-right: 7%;
}

.landowner-main td:nth-child(4) {
  width: 241px;
}

.hunter-ditailsdiv td:nth-child(7),
th:nth-child(7) {
  text-align: unset !important;
}

.properties-main td:nth-child(7),
th:nth-child(7) {
  text-align: center;
  padding-right: 1%;
}

.properties-main td:nth-child(3) {
  color: #be7637 !important;
}

.reservation_div td:nth-child(2) {
  color: #be7637 !important;
}

.reservation_div td:nth-child(7) {
  color: #be7637 !important;
}

.landowner-ditailsdiv td:nth-child(3) {
  text-align: center;
  padding-right: 20%;
}

/* .search-div {
  display: flex;
  align-items: center;
} */

.input-wrapper {
  position: relative;
  width: 30%;
}

.coupon-wrapper {
  position: relative;
  width: 50%;
}

.reservation-wrapper {
  position: relative;
  width: 50%;
}

.hunter-search {
  width: 100%;
  padding-right: 30px;
  /* Add padding to make space for the clear button */
}

.reservation-search {
  width: 100%;
  padding-right: 30px;
  /* Add padding to make space for the clear button */
}

.clear-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #aaa;
}

.clear-btn:hover {
  color: #000;
}

.add-btn {
  margin-left: 0px;
}


.pro-img {
  width: 26px;
  height: 20px;
}

.properties-main .Action_approved .reservation-actionbtn {
  background-color: #d1f4dd;
  border: 1px solid #d1f4dd;
  color: #17c653;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.properties-main .Action_reject .reservation-actionbtn {
  background-color: #fcc;
  border: 1px solid #fcc;
  border-radius: 16px;
  color: #f8285a;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 6px 18px;
}

.properties-main .Action_pending .reservation-actionbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  color: #be7637;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.pro-imgdiv {
  border: 0px solid #be763733;
  border-radius: 0px;
  margin: 26px 0px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
}

.pro-imgdiv1 img {
  width: 100%;
  max-width: 435px;
  height: 335px;
  object-fit: cover;
}

.pro-btndiv {
  display: flex;
  justify-content: right;
  gap: 10px;
}

.pro-btndiv .Reject {
  background-color: #f15e5e;
  border: 1px solid #f15e5e;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
  width: 235px;
  height: 56px;
  cursor: pointer;
}

.pro-btndiv .Approve {
  background-color: #be7637;
  border: 1px solid #be7637;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
  width: 235px;
  height: 56px;
  cursor: pointer;
}

.pro-btndiv .Reject:disabled,
.pro-btndiv .Approve:disabled {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  color: #666666;
  cursor: not-allowed;
}


.reservation-idtable .add-form {
  padding: unset;
}

.reservation_divider {
  /* display: flex;
  justify-content: space-between; */
  display: flex;
  flex-wrap: wrap;
  height: 95px;
  margin: 25px 0;
}

.reservation_part {
  flex: 1 0 25%;
  box-sizing: border-box;
  /* width: calc(25%); */
  border: 1px solid #be763733;
  background-color: #fbf6f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reservation_part:first-child {
  border-right: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.reservation_part:nth-child(2) {
  border-right: none;
}

.reservation_part:last-child {
  border-left: none !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.reservation_content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 5px;
}

.reservation_content h1 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #be7637;
}

.reservation_content p {
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.add-table {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #bd753e30;
  margin: 0;
  padding: 0;
}

.css-8atqhb {
  width: 100%;
  max-width: 670px;
  margin: auto;
}

.managecoupons-table {
  padding: unset;
}

.managecoupons-table .add-table {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom: none !important;
}

/* =========================== pagination ==================== */

.table> :not(caption)>*>* {
  padding: 14px 20px;
  height: 48px;
  border-color: #be763726;
}
ul.pagination {
  /* height: 64px; */
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: end;
  padding: 10px 15px;
  gap: 10px;
  /* border: 1px solid #be763726; */
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  margin: 0;
}

.page-item {
  margin: 0;
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px; /* Adjust padding as needed */
  border: 0px solid transparent; /* Make sure there's some border to see the effect */
  border-radius: 0px;
  text-decoration: none;
  color: #007bff;
  cursor: pointer;
  width: 34px; /* Set a fixed width for the box */
  height: 34px; /* Set a fixed height for the box */
  transition: background-color 0.3s ease;
}

.page-link:hover {
  background-color: #f0f0f0;
}

.page-item.active .page-link {
  background-color: #be763709;
  color: white;
  border: 1px solid #be7637;
}


li.active {
  height: 36px;
  width: 36px;
  padding: 15px;
  border: 1px solid #be7637 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #d188451a !important;
  color: #be7637;
}

li.previous {
  width: auto !important;
  border: none !important;
  padding: 0;
}

li.previous.disabled {
  width: auto;
  border: none;
  padding: 0;
}

li.next {
  width: auto;
  border: none !important;
  padding: 0 !important;
}

li.active a {
  color: #be7637 !important;
}

ul.pagination a {
  text-decoration: none;
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
}

ul.pagination li {
  height: 36px;
  width: 36px;
  padding: 15px;
  border: 1px solid #be763726;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: white;
}


.header-btndiv1 {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 15px;
}
.header-btndiv1 button {
  background-color: #be7637;
  border: 1px solid #be763733;
  color: #f8f8f8;
  width: 188.5px;
  height: 50px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 14.5px;
} 

.header-empty{
  text-align: center;
  max-width: 100%;
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.manage-editbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  border-radius: 16px;
  color: #be7637;
  font-size: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 6px 12px;
  padding: 6px 18px;
}

.action-icons {
  display: flex;
  gap: 10px;
}

.action-icons i {
  color: #be7637;
}
.action-icons img {
  background: white;
  width: 28px;
  height: 28px;
  padding: 4.5px;
  border-radius: 5px;
  cursor: pointer;
}



.manage-editbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  color: #be7637;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.manage-editbtn i {
  margin-right: 8px;
}
.manage-editbtn {
  background-color: #f6e7da;
  border: 1px solid #f6e7da;
  color: #be7637;
  padding: 6px 18px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.manage-editbtn i {
  margin-right: 8px;
}


.manage-deletebtn {
  background-color: #ffcccc;
  border: 1px solid #ffcccc;
  color: #f8285a;
  padding: 6px 18px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.manage-deletebtn i {
  margin-right: 8px;
  color: #f8285a;
}

.form-select-select {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #be763733;
  margin-bottom: 0px !important;
}

.css-13cymwt-control , .css-t3ipsp-control {
  min-height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid #be763733 !important;
}

.createcoupon-checkbox input[type="checkbox"] {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-top: 5px;
  accent-color: #be7637;
}

.add-form.pagination-table .add-table th {
  min-width: 185px;
}

main.MuiBox-root.css-1v5u2yv {
  max-width: calc(100% - 300px);
  overflow: auto;
  max-height: 100vh;
}
.add-table {
  overflow: auto;
  max-height: calc(100vh - 390px);

}


.add-form.pagination-table .add-table th:nth-child(8) {
  min-width: 100px !important;
}

.add-form.pagination-table .add-table th:nth-child(9) {
  min-width: 80px !important;
}
.membertype{
  height: 48px;
  border-radius: 10px;
  border: 1px solid #be763733;
  padding: 10px;
  width: 100%;
  max-width: 200px;
  background-color: transparent;
}
.pending-refund:last-child {
  min-width: 185px !important;
}
.hunder_head_row_tabel {
  display: flex;
  width: 100%;  
  justify-content: end;
  gap: 10px;
}

.hunder_head_row_tabel button.add-btn {
  margin: 0 !important;
}


.loader_section {
  position: relative;
  z-index: 99;
}
.res_loader {
  position: fixed;
  top: 0;
  right: 0;
  width: 90%;
  height: 100vh;
  align-content: center;
  background: #ffffff8f;
  backdrop-filter: blur(1px);
}
.res_loader  .spinner-border.text-primary {
  position: absolute;
  right: 0;
  margin: auto;
  left: 0;
  width: 50px;
  height: 50px;
  border-width: 5px !important;
}

.rowperpage {
  align-items: center;
  border: 1px solid #be763726;
  border-radius: 0 0 10px 10px;
  border-top: none;
  color: #5a5a5a !important;
  display: inline-flex;
  font-size: 14px !important;
  font-weight: 400 !important;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 5px 15px;
  text-decoration: none;
  width: 100%;
}
.perpagecount select {
  border: 1px solid #be763726;
  border-radius: 4PX;
  color: #5a5a5a;
  padding: 6px;
  width: auto;
  background-color: transparent;
}

.perpagecount {
  display: flex;
  align-items: center;
  gap: 10px;
}



/* ================================================ */

.add-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;

}

/* Handle */
.add-table::-webkit-scrollbar-thumb {
  background: #be76379c !important; 
}



.text-red {
  color: red;
}

.icon-spacing {
  margin-left: 8px;
}

.text-green {
  color: green;
}


.label-cancel .ribbon-red {
  background-color: #fcc;
  color: #f8285a;
  display: block;
  font-size: 10px;
  font-weight: 500;
  left: 50px;
  line-height: 15px;
  min-height: 22px;
  padding: 3px 14px;
  position: absolute;
  top: -22px;
  width: auto;
}

.label-cancel .ribbon-red:after, .label-cancel .ribbon-red:before {
  border-left: 11px solid #fcc;
  content: "";
  display: inline-block;
  position: absolute;
  right: -10px;
}

.label-cancel .ribbon-red:before {
  border-bottom: 11px solid #0000;
  top: 0;
}
.label-cancel {
  position: relative;
}

.label-cancel .ribbon-red:after {
  border-top: 11px solid #0000;
  bottom: 0;
}

.label-cancel .ribbon-red:after, .label-cancel .ribbon-red:before {
  border-left: 11px solid #fcc;
  content: "";
  display: inline-block;
  position: absolute;
  right: -10px;
}


.add-table.landowner_table {
  max-height: calc(100vh - 300px);
}

.add-table.manage_coupon_table {
  max-height: calc(100vh - 270px);
}

.add-table.property_table {
  max-height: calc(100vh - 300px);
}
.add-table.landowner_table td {
  min-width: 250px;
}
add-table.property_table th {
  min-width: 180px;
}



@keyframes dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

.loading-dots::after {
  content: "";
  animation: dots 1.5s steps(3, end) infinite;
}


.header-btndiv1 .clear-btn {
  background: none !important;
  border: none !important;
  color: #aaa !important;
  cursor: pointer;
  font-size: 1.4em;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  line-height: normal !important;
}